import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import imgPerson from "@images/common/no-avatar.jpg";

// components
import WhisperSTTComponent from './WhisperSTTComponent';
import TextToSpeech from './TextToSpeech';

// react-redux
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';

//Components Chat Voice
import { WebSocketAudioHandler } from "../ChatVoice/WebSocket";
import SpeechRealTime from './SpeechRealTime';

const StyledImage = styled.img`
    width: 110px;
    height: 110px;
    flex-shrink: 0;
    border-radius: 50%;
    object-fit: cover;
    background: #fff;
    @media (max-width: 768px) {
        width: 80px;
        height: 80px;
    }
`;

const PatientLabel = styled.div`
    color: #5ecdfb;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    margin-top: 20px;
`;

const ImageLabelWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    text-align: center;
    width: 100%;
    height: 100%;
    padding-top: 50px;
    @media (max-width: 768px) {
        padding-top: 30px;
    }
`;

const ResponseText = styled.div`
    color: #fff;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
    max-height: 22%;
    overflow-y: auto;
    margin-top: 20px;
    @media (max-width: 768px) {
        font-size: 16px;
        max-width: 90%;
        max-height: 38%;
    }
`;

const BlinkingDiv = styled.div`
    position: relative;
    ${({ audioStarted }) => audioStarted && css`
        &:before, &:after {
            content: "";
            width: 120px;
            height: 120px;
            border: 14px solid rgba(20, 91, 173, 0.8);
            display: block;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: -1;
            @media (max-width: 768px) {
                width: 100px;
                height: 100px;
            }
        }
        &:before {
            -webkit-animation: hWnWDR 0.75s cubic-bezier(.5,0,1,1) infinite alternate;
            animation: hWnWDR 0.75s cubic-bezier(.5,0,1,1) infinite alternate;
        }
        &:after {
            -webkit-animation: hWnWDR 0.75s cubic-bezier(.5,0,1,1) infinite alternate;
            animation: hWnWDR 0.75s cubic-bezier(.5,0,1,1) infinite alternate;
            animation-delay: 0.375s;
            -webkit-animation-delay: 0.375s;
        }

        @-webkit-keyframes hWnWDR {
            from {
                transform: translate(-50%, -50%) scale(1);
                opacity: 0.8;
            }
            to {
                transform: translate(-50%, -50%) scale(1.2);
                opacity: 0.4;
            }
        }
        @keyframes hWnWDR {
            from {
                transform: translate(-50%, -50%) scale(1);
                opacity: 0.8;
            }
            to {
                transform: translate(-50%, -50%) scale(1.2);
                opacity: 0.4;
            }
        }
    `}
`;

// Hàm này có 3 loại voice (API, realtime với gpt4o, Websocket )
function FrameChat(props) {
    const { isShowForSwitch, ChatMode, responseUserMessage, data } = props;
    let { id } = useParams();
    const [audioFinished, setAudioFinished] = useState(false);
    const [audioStarted, setAudioStarted] = useState(false);
    const [statusGenerateAudio, setStatusGenerateAudio] = useState('idle');
    const [patientResponse, setPatientResponse] = useState(null);

    // Case Data
    const caseData = useSelector((state) => state?.cases || []);
    const caseResponse = caseData?.data?.Case;

    //Characters
    const Characters = caseResponse?.Characters;
    const Patient = Characters?.Patient;
    const PatientCharacter = Patient?.Character;
    const patientNameText = Patient?.DisplayName;
    const photoPatient = PatientCharacter?.StandardSquarePhoto || imgPerson;

    //Chat Voice Condition
    const showSwitchVoiceText = isShowForSwitch ? "d-block" : "d-none";
    const chatModeClass = ChatMode === "switch_voice_text" ? showSwitchVoiceText : "";

    const handleAudioStart = () => {
        setAudioStarted(true); // Set audioStarted to true when audio starts playing
        console.log("Audio has started playing");
    };

    const handleAudioEnd = () => {
        setAudioFinished(true);
        setAudioStarted(false); // Reset audioStarted to false when audio ends
        console.log("Audio has finished playing");
    };

    const handleStatusChange = (newStatus) => {
        setStatusGenerateAudio(newStatus);
        console.log("Status changed to:", newStatus);
    };

    // useEffect để gán state cho patientResponse khi statusGenerateAudio là 'playing'
    useEffect(() => {
        if (statusGenerateAudio === 'playing') {
            setPatientResponse(responseUserMessage?.PatientResponse);
        }
    }, [statusGenerateAudio, responseUserMessage?.PatientResponse]);

    return (
        <div className={`frameChat__fullBox position-relative ${chatModeClass}`}>
            {/* Chức năng stream mới */}
            {parseInt(id) === 653 ? (
                <>
                    {ChatMode === "voice" &&
                        <SpeechRealTime 
                            id={id}
                            data={data}
                            setPatientResponse={setPatientResponse}
                            photoPatient={photoPatient}
                            patientNameText={patientNameText}
                        />
                    }
                    {ChatMode === "streaming" &&
                        <WebSocketAudioHandler
                            CaseId={id}
                            data={data}
                            photoPatient={photoPatient}
                            patientNameText={patientNameText}
                        />
                    }
                </>
            ) : (
                <>
                    {/* Chức năng stream cũ */}
                    <ImageLabelWrapper>
                        <BlinkingDiv audioStarted={audioStarted}>
                            <StyledImage
                                src={photoPatient}
                                alt={patientNameText}
                                className={`img-fluid`}
                            />
                        </BlinkingDiv>
                        <PatientLabel>{patientNameText || "Patient"}</PatientLabel>
                        {patientResponse &&
                            <ResponseText className="scrollbarStyle-small">{patientResponse}</ResponseText>
                        }
                    </ImageLabelWrapper>
                    <WhisperSTTComponent
                        {...props}
                        apiKey={`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`}
                        audioFinished={audioFinished}
                        audioStarted={audioStarted}
                        statusGenerateAudio={statusGenerateAudio}
                        contentSpeechAudio={responseUserMessage?.PatientResponse}
                    />
                    <TextToSpeech
                        {...props}
                        onAudioStart={handleAudioStart}
                        onAudioEnd={handleAudioEnd}
                        onStatusChange={handleStatusChange}
                        contentSpeechAudio={responseUserMessage?.PatientResponse}
                        statusGenerateAudio={statusGenerateAudio}
                    />
                </>
            )}
        </div>
    );
}

export default FrameChat;